import { Firestore, collection } from 'firebase/firestore'

/**
 * Retrieves a filtered list of briefings based on user permissions.
 *
 * @param db - The Firestore instance.
 * @returns A computed reference to an array of filtered briefings.
 */
export const useBriefingsList = (db:Firestore) => {

  const permission = usePermissions()
  const briefingsRef = useCollection(collection(db, 'briefings'), { ssrKey: 'briefings-query' })

  return computed(() => briefingsRef.value.filter(briefing => permission.hasBriefingPermission(briefing as FsbdBriefingCreate)))

}
